// DYNAMIC PARAMETER
export const PARAM_LIEGENSCHAFT = ':liegenschaftId';
export const PARAM_ZAEHLPUNKT = ':zaehlpunktId';
export const PARAM_HELP_SECTION = ':sectionId';
export const PARAM_BERICHT_SECTION = ':sectionId';

// KIP
export const PATH_SIGN_UP = `/registrieren`;
export const PATH_AUTH_CHECK = '/check';

// MAIN ROUTES
export const PATH_DASHBOARD = '/start';
export const PATH_HILFEBEREICH = '/hilfebereich';
export const PATH_HILFEBEREICH_SECTION = `${PATH_HILFEBEREICH}/${PARAM_HELP_SECTION}`;
export const PATH_HILFEBEREICH_KOMMUNE_SECTION = `${PATH_HILFEBEREICH}/kommune/${PARAM_HELP_SECTION}`;
export const PATH_HILFEBEREICH_VERBAND_SECTION = `${PATH_HILFEBEREICH}/verband/${PARAM_HELP_SECTION}`;
export const PATH_SETTINGS = '/einstellungen';
export const PATH_DATA_INPUT = '/daten-eingabe';
export const PATH_DATA_EXPORT = '/daten-export';
export const PATH_ENERGY_REPORT = '/energieberichte';
export const PATH_ENERGY_REPORT_SECTION = `${PATH_ENERGY_REPORT}/${PARAM_BERICHT_SECTION}`;
export const PATH_LIEGENSCHAFTEN = '/liegenschaften';
export const PATH_ZAEHLPUNKTE = '/zaehlpunkte';
export const PATH_ZAEHLPUNKTE_VON_LIEGENSCHAFT = `/zaehlpunkte?liegenschaften=${PARAM_LIEGENSCHAFT}`;
export const PATH_ZAEHLPUNKTE_QRCODES = `${PATH_ZAEHLPUNKTE}/qrcodes`;
export const PATH_RECHNUNGEN = '/rechnungen';
export const PATH_RECHNUNGEN_VON_LIEGENSCHAFT = `/rechnungen?liegenschaften=${PARAM_LIEGENSCHAFT}`;
export const NOT_FOUND_PATH = '/not-found';
export const PATH_NEUER_KUNDE = '/neuer-kunde';

// DETAIL VIEWS
export const PATH_LIEGENSCHAFT_DETAIL = `${PATH_LIEGENSCHAFTEN}/${PARAM_LIEGENSCHAFT}`;
export const PATH_ENERGY_REPORT_FEHLENDE_DATEN = `${PATH_ENERGY_REPORT}/fehlende-daten`;
export const PATH_ENERGY_REPORT_FEHLENDE_BEWEGUNGSDATEN_ERFASSEN = `${PATH_ENERGY_REPORT}/fehlende-zaehlerstaende-erfassen/liegenschaft/${PARAM_LIEGENSCHAFT}/zaehlpunkt/${PARAM_ZAEHLPUNKT}`;
export const PATH_ENERGY_REPORT_FEHLENDE_RECHNUNGSDATEN_ERFASSEN = `${PATH_ENERGY_REPORT}/fehlende-rechnungen-erfassen/liegenschaft/${PARAM_LIEGENSCHAFT}/zaehlpunkt/${PARAM_ZAEHLPUNKT}`;
export const PATH_ENERGY_REPORT_FEHLENDE_ZAEHLPUNKTE_ERFASSEN = `${PATH_ENERGY_REPORT}/fehlende-zaehlpunkte-erfassen/liegenschaft/${PARAM_LIEGENSCHAFT}`;
export const PATH_ENERGY_REPORT_KOMMUNE = `${PATH_ENERGY_REPORT}/kommune`;
export const PATH_ENERGY_REPORT_VERBAND = `${PATH_ENERGY_REPORT}/verband`;
export const PATH_HILFEBEREICH_KOMMUNE = `${PATH_HILFEBEREICH}/kommune`;
export const PATH_HILFEBEREICH_VERBAND = `${PATH_HILFEBEREICH}/verband`;
export const PATH_STATUS_DATENEINGABE = `${PATH_DATA_INPUT}/status`;
export const PATH_STATUS_DATENEINGABE_DETAILS = `${PATH_DATA_INPUT}/status/${PARAM_LIEGENSCHAFT}`;

// DATA INPUT
export const PATH_NEUER_ZAEHLPUNKT = `${PATH_DATA_INPUT}/neuer-zaehlpunkt`;
export const PATH_NEUER_ZAEHLPUNKT_LIEGENSCHAFT = `${PATH_NEUER_ZAEHLPUNKT}/liegenschaft/${PARAM_LIEGENSCHAFT}`;
export const PATH_NEUER_ZAEHLERSTAND = `${PATH_DATA_INPUT}/zaehlerstand-erfassen`;
export const PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT = `${PATH_NEUER_ZAEHLERSTAND}/liegenschaft/${PARAM_LIEGENSCHAFT}`;
export const PATH_QRCODE_RESOLVER = `/qr/l/${PARAM_LIEGENSCHAFT}/z/${PARAM_ZAEHLPUNKT}`;
export const PATH_NEUER_ZAEHLERSTAND_LIEGENSCHAFT_ZAEHLPUNKT = `${PATH_NEUER_ZAEHLERSTAND}/liegenschaft/${PARAM_LIEGENSCHAFT}/zaehlpunkt/${PARAM_ZAEHLPUNKT}`;
export const PATH_NEUE_RECHNUNG = `${PATH_DATA_INPUT}/neue-rechnung`;
export const PATH_NEUE_MASSNAHME = `${PATH_DATA_INPUT}/neue-massnahme`;
export const PATH_NEUE_LIEGENSCHAFT = `${PATH_DATA_INPUT}/liegenschaft-anlegen`;
export const PATH_NEUEN_KUNDEN_KOMMUNE_ANLEGEN = `${PATH_NEUER_KUNDE}/kommune`;
export const PATH_NEUEN_KUNDEN_VERBAND_ANLEGEN = `${PATH_NEUER_KUNDE}/verband`;
export const PATH_NEUER_KUNDE_DATENIMPORT = '/neuer-kunde-datenimport';
