import styled from '@emotion/styled';

// NAVIGATION
export { default as IconDateneingabe } from '@material-ui/icons/Create';
export { default as IconEnergiebericht } from '@material-ui/icons/Description';
export { default as IconHilfebereich } from '@material-ui/icons/Help';
export { default as IconMeineKommune } from '@material-ui/icons/Home';
export { default as IconZaehlpunkte } from '@material-ui/icons/PinDrop';
export { default as IconLiegenschaft } from '@material-ui/icons/Room';
export { default as IconEinstellungen } from '@material-ui/icons/Settings';

// ENTITIES
export { default as IconMassnahme } from '@material-ui/icons/Build';
export { default as IconManuellerZaehler } from '@material-ui/icons/Event';
export { default as IconRechnung } from '@material-ui/icons/Receipt';
export { default as IconRLMIMSysZaehler } from '@material-ui/icons/SettingsInputAntenna';
export { default as IconStrassenbeleuchtung } from '@material-ui/icons/WbIncandescent';

// OTHER
export { default as IconUp } from '@material-ui/icons/ArrowUpward';
export { default as DeleteIcon } from '@material-ui/icons/Delete';
export { default as FindInPageIcon } from '@material-ui/icons/FindInPage';
export { default as IconDownload } from '@material-ui/icons/GetApp';
export { ReactComponent as IconAssignmentLate } from 'src/assets/limbo_assignment_late.svg';
export { ReactComponent as IconAssignmentTurnedIn } from 'src/assets/limbo_assignment_turned_in.svg';

import { default as IconHilfebereich } from '@material-ui/icons/Help';

export const IconHilfebereichKachel = styled(IconHilfebereich)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconEnergiebericht } from '@material-ui/icons/Description';

export const IconEnergieberichtKachel = styled(IconEnergiebericht)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconNeuerKunde } from '@material-ui/icons/Person';

export const IconNeuerKundeKachel = styled(IconNeuerKunde)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconDatenimport } from '@material-ui/icons/CloudUpload';

export const IconDatenimportKachel = styled(IconDatenimport)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconMassnahme } from '@material-ui/icons/Build';

export const IconMassnahmeKachel = styled(IconMassnahme)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconRechnung } from '@material-ui/icons/Receipt';

export const IconRechnungKachel = styled(IconRechnung)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconNeueLiegenschaft } from '@material-ui/icons/HomeWork';

export const IconNeueLiegenschaftKachel = styled(IconNeueLiegenschaft)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconNeuerZaehlpunkt } from '@material-ui/icons/PinDrop';

export const IconNeuerZaehlpunktKachel = styled(IconNeuerZaehlpunkt)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { default as IconNeueProduktionVerbrauch } from '@material-ui/icons/InsertChart';

export const IconNeueProduktionVerbrauchKachel = styled(
  IconNeueProduktionVerbrauch
)`
  font-size: 3rem;
  margin-bottom: 30px;
`;

import { ReactComponent as IconStatusDateneingabe } from 'src/assets/limbo_pending_action.svg';

export const IconStatusDateneingabeKachel = styled(IconStatusDateneingabe)(
  ({ theme }) => `
  font-size: 3rem;
  margin-bottom: 30px;
  color: ${theme.palette.secondary.main};
`
);
