import grey from '@material-ui/core/colors/grey';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  props: {
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true,
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
      size: 'large',
    },
  },
  overrides: {
    MuiButton: {
      containedSizeLarge: {
        fontSize: '1rem',
        fontWeight: 700,
        textTransform: 'uppercase',
      },
    },
    MuiPaper: {
      rounded: { borderRadius: '12px' },
    },
    MuiSelect: {
      icon: {
        color: '#F25050',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: 'rgb(244 64 64 / 0.10)',
          '&:hover': {
            backgroundColor: 'rgb(244 64 64 / 0.10)',
          },
        },
      },
      button: {
        '&:hover': {
          backgroundColor: 'rgb(244 64 64 / 0.10)',
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '0.9rem',
        lineHeight: 1.5,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '1rem',
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'space-between',
      },
    },
    MuiStepLabel: {
      root: {
        '&$active': {
          fontWeight: 600,
          fontSize: '0.9rem',
        },
      },
      alternativeLabel: {
        fontWeight: 600,
        fontSize: '0.9rem',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#253048',
      },
    },
  },
  palette: {
    text: {
      primary: '#253048',
    },
    background: {
      default: '#F2F2F2',
    },
    primary: {
      main: '#253048',
    },
    secondary: {
      main: '#F25050',
    },
    grey,
    action: {
      disabledBackground: '#E8E8E8',
      disabled: '#D2D2D2',
    },
    error: {
      main: '#F25050',
    },
  },
  typography: {
    fontFamily: ['Open Sans', 'Roboto', 'Helvetica Neue'].join(','),
    h1: {
      fontWeight: 700,
      fontSize: '1.5rem',
      textTransform: 'uppercase',
    },
    h2: {
      fontWeight: 700,
      fontSize: '1.2rem',
      textTransform: 'uppercase',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1rem',
      textTransform: 'uppercase',
    },
    h4: {
      fontWeight: 700,
      fontSize: '0.9rem',
    },
  },
  spacing: (factor) => `${0.25 * factor}rem`, // (Bootstrap strategy)
});

export type LocalTheme = typeof theme;

export default theme;

declare module '@emotion/react' {
  export interface Theme extends LocalTheme {}
}
